@import '@flixbus/honeycomb-react/dist/scss/honeycomb-helpers.scss';

html,
body,
#root {
  margin: 0;
  padding: 0;
  height: 100vh;
}

.header-brand img {
  height: 30px;
}

.header-section {
  padding-top: cssvar(spacing-2);
  background: var(--hcr-grayscale-0-color);
}

.header-user-widget {
  white-space: nowrap;
}

.full-width-divider {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: var(--hcr-grayscale-30-color);
}

.full-height {
  height: 100vh;
}

.full-width {
  width: 100%;
}

.table-hide-header {
  thead {
    display: none;
  }
}

.loading-content-overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  min-height: calc(50vh - 60px);
}

.partner-depot-header {
  display: flex;

  justify-content: space-between;
  align-items: center;

  & > div {
    display: flex;
  }
}

.cpc-confirm-popup-overlay {
  z-index: 1300;
}

.nav-horizontal-container {
  position: relative;

  .nav-horizontal-button {
    position: absolute;
    right: 0;
  }
}

// Regional costs
.regional-costs-countries,
.regional-costs-years,
.partner-costs-partners,
.partner-costs-years {
  display: flex;

  & > div {
    display: flex;
  }

  div:first-child {
    flex: 1;
  }

  div:last-child {
    margin-left: cssvar(spacing-half);
    align-items: flex-end;
    button + button {
      margin-left: cssvar(spacing-half);
    }
  }
}

.partner-costs-year-quality-check {
  display: flex;
  padding-top: 30px;
  @media only screen and (max-width: 1200px) {
    padding-top: 10px;
  }
  div:first-child {
    display: flex;
    legend + div {
      margin-left: cssvar(spacing-2);;
    }
  }
}

.driver-salary-periods-container {
  display: flex;
  justify-content: space-between;

  border-bottom: 1px solid cssvar(primary-line-color);
  padding-bottom: cssvar(spacing-3);
  margin-bottom: cssvar(spacing-3);

  &.driver-salary-periods-latest {
    border-bottom: 0;
    padding-bottom: cssvar(spacing-4);
    margin-bottom: cssvar(spacing-4);
  }

  & > div {
    display: flex;
  }

  .driver-salary-periods-week-day {
    flex-direction: column;

    width: 140px;
    .driver-salary-periods-time-input {
      padding-bottom: cssvar(spacing-2);
      margin-bottom: cssvar(spacing-2);
    }
  }
}

.driver-salary-periods-footer,
.line-schedules-footer {
  z-index: 2;
  box-shadow: var(--hcr-primary-box-shadow);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  width: 100%;
  min-height: 26px;

  padding: cssvar(spacing-2) 0;
  background-color: var(--hcr-grayscale-0-color);
}

.driver-salary-periods-validation-message {
  display: flex;
  flex: 1;
  height: 100%;

  justify-content: flex-end;
  align-items: center;

  & > div {
    display: flex;
  }

  & > span:first-child {
    min-width: cssvar(spacing-3);
    margin-right: cssvar(spacing-half);
  }
  & > span:last-child {
    color: cssvar(primary-content-color);
    display: inline-block;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
  }
}

.driver-salary-periods-time-input-container {
  position: relative;

  &:hover {
    .driver-salary-periods-time-input-delete {
      display: block;
    }
  }

  .driver-salary-periods-time-input-delete {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 0;

    z-index: 2;
  }
}

.data-table-with-actions {
  tr {
    td:last-child {
      text-align: right;
    }
  }
}

.cpc-table-title {
  margin-bottom: cssvar(spacing-half);
}

.cpc-table-subtitle {
  margin-bottom: cssvar(spacing-2);
}

// Home
.home-button {
  margin-left: cssvar(spacing-3);
}

.filter-container {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
  gap: 10px;
  box-sizing: border-box;
}

.justify-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.data-table-action-buttons {
  padding: var(--hcr-spacing-3);

  .power-bi-links {
     @extend .justify-end;
     padding-right: var(--hcr-spacing-4);
  }
}

.calculations-container {
  width: 100%;
  overflow: scroll;
  box-sizing: border-box;

  .table-container {
    display: inline-block;
    margin: cssvar(spacing-3);

    .table-scroll {
      margin-bottom: 23px;
    }

    .calculations-table table {

      thead tr th {
        white-space: pre-wrap;
        height: 115px;
      }
    }

    // makes subtotal rows sticky
    // it's possible that the table has up to 10 subtotal sticky rows (row per currency)
    .calculated-table-data table .calculated-subtotal-row {
      td, th {
        position: sticky;
        z-index: 5;
        font-weight: 600;
        background-color: var(--hcr-grayscale-0-color);
      }
    }

    @for $noOfSubtotalRows from 1 through 10 {
      .calculated-table-data-#{$noOfSubtotalRows} {
        @for $subtotalRowNo from 1 through $noOfSubtotalRows {
          table tbody {
            .calculated-subtotal-row:nth-child(#{$subtotalRowNo}) {
              $topVal: 100;
              @if $subtotalRowNo == 1 {
                $topVal: 75;
              }
              td, th {
                top: ($topVal + ($subtotalRowNo * 45px));
              }
            }

            .calculated-subtotal-row:nth-child(#{$subtotalRowNo}) {
              $topVal: 70;
              @if $subtotalRowNo == 1 {
                $topVal: 60;
              }
              td, th {
                top: ($topVal + ($subtotalRowNo * 45px));
              }
            }
          }
        }
      }
    }
  }
}

.calculation-select-group {
  & > ul {
    width: 100%;

    & > li {
      flex-grow: 1;

      & > input {
        // removes extra space on SelectGroup
        width: 0;
      }
    }
  }
}

.align-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-footer {
  @extend .align-center;
  width: 100%;
  padding-bottom: 20px;
}

// Production Cost
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - var(--hcr-spacing-3) - 120px);
}

.production-cost-header-subtitle {
  position: absolute;
  bottom: -20px;
  color: var(--hcr-content-secondary-color);
}

.production-cost-criteria-section {
  padding: cssvar(spacing-3);
  background: var(--hcr-grayscale-0-color);
  border-top: 1px solid var(--hcr-grayscale-30-color);
  border-bottom: 1px solid var(--hcr-grayscale-30-color);
  margin-top: cssvar(spacing-5);
  margin-bottom: cssvar(spacing-3);

  fieldset {
    margin: 0;
  }

  display: flex;
  justify-content: left;

  & > div {
    display: flex;
    margin-right: cssvar(spacing-3);

    & > div {
      width: 100%;
    }

    input,
    select {
      width: 100%;
    }
  }
}

.planning-period-calendar {
  position: absolute;
  width: auto;
}

.production-cost-line-schedule-select {
  position: relative;

  margin-left: cssvar(spacing-6);
  margin-right: cssvar(spacing-6);
  margin-bottom: cssvar(spacing-6);

  h2 {
    margin-top: 0;
  }

  .production-cost-line-schedule-short {
    display: flex;

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-right: cssvar(spacing-4);

    div {
      margin-right: cssvar(spacing-1);
    }
  }
}

.production-cost-line-schedule-filter {
  display: flex;
  flex-direction: row;

  .production-cost-line-schedule-field {
    display: flex;
    margin-right: cssvar(spacing-3);
  }
}

.line-schedules-footer {
  padding-left: cssvar(spacing-3);
  padding-right: cssvar(spacing-3);

  display: flex;
  justify-content: flex-end;
}

.production-cost-line-schedule-actions {
  position: absolute;
  right: 0;
  top: 0;

  margin-top: cssvar(spacing-3);
  margin-right: cssvar(spacing-3);
}

.line-schedules-footer {
  &.second {
    justify-content: space-between;
  }
}

.production-cost-section-content {
  display: flex;
  flex-direction: column;

  margin-top: cssvar(spacing-1);
  margin-bottom: cssvar(spacing-1);

  .production-cost-section-line {
    margin-bottom: cssvar(spacing-2);
  }

  & > div {
    display: flex;
  }

  .production-cost-section-table {
    overflow-y: scroll;
    th {
      max-width: 200px;
      white-space: normal;
    }
  }
}

.info-only,
.info-only:focus {
  border: 0;
  padding: 0;
}

.popup-table [class^="hcr-popup__body"] {
  width: 80%;
  max-width: 100%;

  table {
    thead tr th {
      white-space: pre-wrap;
      height: 115px;
    }
  }
}

.pagination {
  padding: 10px 20px 10px 20px;
  fieldset {
    margin: 0;
  }
  * {
    font-size: 1.1rem;
  }
  input {
    width: 90px;
  }
  .total-pages {
    padding-top: 6px;
  }
  .records-per-page {
    display: flex;
    justify-content: space-between;
  }
  .text-records {
    margin-left: 10px;
    top: 5px;
    position: relative;
  }
}

.inline-edit {
  .inline-edit-save {
      width: 500px;
      margin-top: 30px;
      display: flex;
      input {
        width: 300px
      }
      span {
        margin: 5px;
        :hover {
          fill: var(--hcr-button-primary-color);
        }
      }
      span:first-of-type {
        :hover {
          fill: var(--hcr-button-primary-color);
        }
      }
  }
  .inline-edit-button {
    display: flex;
    span {
      margin-left: 10px;
      :hover {
        fill: var(--hcr-button-primary-color);
      }
    }
  }
}

.pagination {
  * {
    font-size: 1.1rem;
  }
  input {
    width: 90px;
  }
  .total-pages {
    padding-top: 6px;
  }
  .records-per-page {
    display: flex;
    justify-content: space-between;
  }
  .text-records {
    margin-left: 10px;
    top: 5px;
    position: relative;
  }
}

.data-table-action-popup {
  [class^="hcr-popup__body"] {
    width: 75%;
    max-width: 100%;
  }
}

table tbody tr[class*='hcr-data-table__row--selected']:nth-of-type(odd) {
  background-color: var(--hcr-brand-primary-color) !important;
}

/* FLEXBOX */
.no-wrap {
  flex-wrap: nowrap;
}

.flex-center {
  display: flex;
  align-items: center;
}

.flex-right {
  display: flex;
  justify-content: flex-end;
}

.header-widgets {
  justify-self: center;
}

.flex-1 {
  flex: 1;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* END FLEXBOX */

/* COLORS */
.grayscale-70-color {
  color: var(--hcr-grayscale-70-color);
}

.content-secondary-color {
  color: var(--hcr-content-secondary-color);
}

.warning-color {
  color: var(--hcr-warning-color);
}

.danger-color {
  color: var(--hcr-danger-color);
}
/* END COLORS */

.popup-no-content-alignment .popup-content {
  text-align: start;
}

.font-bold {
  font-weight: bold;
}

.no-margin {
  margin: 0;
}

.progress-tracker {
  display: flex;
  justify-content: flex-end;
}

.progress-tracker-item {
  max-width: 300px;
  flex: 1;
}

.hidden {
  visibility: hidden;
}

.gap-2 {
  gap: var(--hcr-spacing-2)
}

.gap-10 {
  gap: var(--hcr-spacing-10);
}

.line-schedule-vehicle-nav {
  max-height: 85vh;
  width: 380px;
  padding-left: var(--hcr-spacing-6);
  padding-right: var(--hcr-spacing-6);
  overflow: scroll;

  .hcr-nav-side__text {
    width: 100%;
    text-align: start;
  }

  .hcr-nav-side-subnav__text {
    overflow: auto;
    word-wrap: break-word;
    padding-left: var(--hcr-spacing-half);
    max-width: 240px;
  }
}

.max-width-1500 {
  max-width: 1500px;
}

.dark-theme-button {
  background: var(--hcr-grayscale-100-color);
  color: var(--hcr-ui-primary-light-color);
  min-height: var(--hcr-spacing-5);
  width: var(--hcr-spacing-5);
}

.light-theme-button {
  background: var(--hcr-brand-primary-color);
  color: var(--hcr-ui-primary-light-color);
  min-height: var(--hcr-spacing-5);
  width: var(--hcr-spacing-5);
}
