.container {
  display: flex;
  gap: var(--hcr-spacing-3);
  margin-top: var(--hcr-spacing-8);
  margin-bottom: var(--hcr-spacing-4);
  background: var(--hcr-grayscale-0-color);
}

.planningPeriod {
  position: relative;
  width: 240px;
}

.calculationStatus {
  width: 200px;
}

.calculationName {
  width: 400px;
}

.year {
  width: 200px;
}