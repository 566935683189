.container {
  padding: var(--hcr-spacing-4) var(--hcr-spacing-6) var(--hcr-spacing-10) var(--hcr-spacing-6);
  background-color: var(--hcr-bg-secondary-color);
  flex: 1;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}