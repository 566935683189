.container {
  padding: var(--hcr-spacing-1) var(--hcr-spacing-3);
}

.inputs {
  display: flex;
  flex-direction: column;
  gap: var(--hcr-spacing-3);
  padding-top: var(--hcr-spacing-6);
  padding-bottom: var(--hcr-spacing-6);
}