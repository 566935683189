.header {
  margin: 0 var(--hcr-spacing-6);
}

.body {
  display: flex;
  flex-direction: column;
  gap: var(--hcr-spacing-6);
  flex: 1;
  padding: var(--hcr-spacing-4) var(--hcr-spacing-6) var(--hcr-spacing-12) var(--hcr-spacing-6);
  background-color: var(--hcr-bg-secondary-color);
}