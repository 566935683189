.noRecords {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: var(--hcr-spacing-4);
}

.nameCell {
  display: inline-flex;
  align-items: center;
  position: relative;
  gap: var(--hcr-spacing-half);
  color:var(--hcr-link-color);

  &.warning {
    color: var(--hcr-warning-dark-color);
  }

  .icon {
    position: absolute;
    right: -24px;
  }
}
