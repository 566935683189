.container {
	display: flex;
  flex-direction: column;
  height: 100%;
  border-right: 1px solid var(--hcr-line-primary-color);
}

.lineNumber {
  padding-left: var(--hcr-spacing-6);
  padding-right: var(--hcr-spacing-6);
  color: var(--hcr-content-secondary-color);
}

.scheduleDateRange {
  display: flex;
  gap: var(--hcr-spacing-1);
  font-weight: bold;
}

.vehicleName {
  display: flex;
  gap: var(--hcr-spacing-1);
}
