.container {
  display: flex;
  border: 1px solid var(--hcr-line-primary-color);
  border-radius: var(--hcr-primary-border-radius);
  background-color: var(--hcr-box-bg-color);
  position: relative;
  top: var(--hcr-spacing-2);

  section {
    width: 100%;
  }
}

.fontDanger {
  color: var(--hcr-danger-color);
}