.selector {
  padding: 0;
  color: var(--hcr-content-primary-color);
  font-size: var(--hcr-font-size-primary);
  padding-right: var(--hcr-spacing-6);
  border: 0;
  appearance: none;
  outline: none;
}

.selectorIcon {
  position: absolute;
  right: var(--hcr-spacing-2);
  color: var(--hcr-icon-primary-color);
  pointer-events: none;
}