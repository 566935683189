.subtitle {
  text-align: center;
  margin-top: 0;
}

.accordionContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: var(--hcr-spacing-2);
}

.partnerDetails {
  display: flex;
  flex-direction: column;
}

.noMargin {
  margin: 0;
}