@import '~@flixbus/honeycomb-react/dist/scss/honeycomb-tools';

.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  margin-top: 5%;

  .imageLink {
    div[class*='hcr-img-link__content']::after {
      content: none;
    }

    div[class^="hcr-img-box__img-wrapper"] {
      min-height: 300px;
    }
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: cssvar(spacing-1) 0;
  }
}
