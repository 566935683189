.container {
  display: flex;
  margin-top: var(--hcr-spacing-8);
  margin-bottom: var(--hcr-spacing-4);
  background: var(--hcr-grayscale-0-color);
}

.field {
  margin-right: var(--hcr-spacing-6);
}

.warningMessage {
  gap: var(--hcr-spacing-1);
}