.container {
  display: flex;
  align-items: center;

  div[class^="hcr-select__container"] {
    background: transparent;
    border: none;
  }

  select {
    width: auto;
  }
}