.headerInfo {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--hcr-spacing-3);
}

.title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--hcr-spacing-5);
    margin-bottom: var(--hcr-spacing-1);

    .leftSide {
        margin: 0;
    }
}
